<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="12"> AddModule </CCol>
            </CRow>
          </CCardHeader>
          <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
            <CCardBody>
              <div class="form-group">
                <label for=""
                  ><b
                    >Tanggal Awal Libur <span class="text-danger">*</span></b
                  ></label
                >

                <vuejs-datepicker
                  input-class="form-control"
                  v-model="tanggal_awal_libur"
                  format="dd MMM yyyy"
                ></vuejs-datepicker>
              </div>
              <div class="form-group">
                <label for=""
                  ><b
                    >Tanggal Akhir Libur<span class="text-danger">*</span></b
                  ></label
                >
                <vuejs-datepicker
                  input-class="form-control "
                  v-model="tanggal_akhir_libur"
                  format="dd MMM yyyy"
                ></vuejs-datepicker>
              </div>
              <div class="form-group">
                <label for=""><b>Catatan</b></label>
                <textarea
                  rows="5"
                  class="form-control"
                  placeholder="Keterangan Libur"
                  v-model="catatan_libur"
                ></textarea>
              </div>
              <div class="form-group">
                <label for=""><b>File / Lampiran</b></label>
                <input
                  type="file"
                  class="form-control mb-0"
                  @change="handleFileUpload"
                  accept=".pdf"
                  ref="fileInput"
                />
              </div>
            </CCardBody>
            <CCardFooter>
              <div class="text-right">
                <button
                  type="submit"
                  class="btn btn-primary text-white btn-hover-outline-primary"
                >
                  <i class="fa fa-save"></i> Simpan
                </button>
              </div>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "EditJadwalLiburPelayanan",
  components: {
    vuejsDatepicker,
  },
  data() {
    return {
      tanggal_awal_libur: "",
      tanggal_akhir_libur: "",
      catatan_libur: "",
      lampiran_libur: "",
      idJadwal: this.$route.params.idJadwal,
    };
  },
  methods: {
    storeProcess: function () {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      axios
        .put(
          this.apiLink + "api/crud/jadwal-pelayanan",
          {
            id: this.idJadwal,
            hari_pelayanan: this.hari.value,
            jam_buka_pelayanan: this.jam_buka,
            jam_tutup_pelayanan: this.jam_tutup,
            is_aktif: this.is_aktif.value,
          },
          {
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
          }
        )
        .then(function (response) {
          var res_data = response.data;
          if (res_data.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_user.data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master module",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
              .then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "AllJadwalPelayanan" });
                }
              })
              .catch((error) => {
                var error_res = error.response.data.meta;
                if (error_res.code != 200) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error_res.message,
                  });
                }
              });
          }
        });
    },
    storeProcess: function () {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("tanggal_awal_libur", this.tanggal_awal_libur);
      data.append("tanggal_akhir_libur", this.tanggal_akhir_libur);
      data.append("catatan_libur", this.catatan_libur);
      data.append("lampiran_libur", this.lampiran_libur);
      data.append("id", this.idJadwal);
      var config = {
        method: "post",
        url: this.apiLink + "api/crud/jadwal-libur-pelayanan",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: data,
      };
      axios(config).then(function (response) {
        var res_data = response.data;
        if (res_data.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_user.data.message,
          });
        } else {
          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master module",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          })
            .then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "AllJadwalLiburPelayanan" });
              }
            })
            .catch((error) => {
              var error_res = error.response.data.meta;
              if (error_res.code != 200) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error_res.message,
                });
              }
            });
        }
      });
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      this.lampiran_libur = "";

      // Validate if the file is a PDF
      if (uploadedFile && uploadedFile.type === "application/pdf") {
        this.lampiran_libur = uploadedFile;
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File yang diizinkan hanya .pdf",
        });
        this.resetInputFile();
      }
    },

    resetInputFile() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
  },
  created() {
    axios
      .get(this.apiLink + "api/crud/jadwal-libur-pelayanan", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        params: {
          id: this.idJadwal,
        },
      })
      .then((response) => {
        var res_module = response.data;
        var res_module_data = res_module.data[0];

        if (res_module.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_module.data.message,
          });
        } else {
          this.tanggal_awal_libur = res_module_data.tanggal_awal_libur;
          this.tanggal_akhir_libur = res_module_data.tanggal_akhir_libur;
          this.catatan_libur = res_module_data.catatan_libur;
        }
      });
  },
};
</script>
